.container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.passwordContainer {
    position: relative;
    margin-bottom: 20px;
}

.togglePassword {
    position: absolute;
    right: 10px;
    top: 35px;
    cursor: pointer;
    font-size: 18px;
    color: #888;
}

.togglePassword:hover {
    color: #000;
}

.title {
    margin-bottom: 20px;
    text-align: center;
    color: #1976d2;
}

.error {
    color: red;
    margin-top: 10px;
    font-size: 0.9em;
}

.success {
    color: green;
    margin-top: 10px;
    font-size: 0.9em;
}
