.container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
    margin-bottom: 20px;
    text-align: center;
    #color: #1976d2;
    color: #1b576d;
}

.error {
    color: #ff4d4f;
    margin-top: 10px;
    font-size: 0.85em;
    text-align: center;
}

.success {
    color: #4caf50;
    margin-top: 10px;
    font-size: 0.85em;
    text-align: center;
}

.signupLink {
    color: #1b576d;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.9em;
    margin-top: 15px;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.signupLink:hover {
    color: #1976d2;
}
