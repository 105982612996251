.inputContainer {
    margin-bottom: 20px;
}

.inputContainer label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}

.inputContainer input {
    width: 600px;
    padding: 10px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #ddd;
    outline: none;
    transition: border-color 0.3s ease;
}

.inputContainer input:focus {
    border-color: #1b576d;
}

.success {
    color: #4caf50;
    margin-top: 10px;
    font-size: 0.85em;
    text-align: center;
}




