.container {
    max-width: 400px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.container:hover {
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}

.title {
    margin-bottom: 30px;
    text-align: center;
    color: #333;
    font-size: 24px;
    font-weight: bold;
}

.error {
    color: #ff4d4f;
    margin-top: 10px;
    font-size: 0.85em;
    text-align: center;
}

.linkContainer {
    margin-top: 30px;
    text-align: center;
}

.link {
    display: block;
    margin: 15px 0;
    color: #1b576d;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
}

.link:hover {
    text-decoration: underline;
    color: #1b576d;
}

.passwordContainer {
    position: relative;
}

.togglePassword {
    position: absolute;
    right: 10px;
    top: 35px;
    cursor: pointer;
    font-size: 18px;
    color: #888;
}

.togglePassword:hover {
    color: #000;
}

/* Estilos Responsivos */
@media (max-width: 480px) {
    .container {
        max-width: 90%;
        padding: 20px;
    }

    .title {
        font-size: 20px;
    }

    .link {
        font-size: 12px;
    }
}
