.container {
    padding: 20px;
}

.section {
    margin-bottom: 20px;
}

h2 {
    font-size: 24px;
    color: #1b576d;
}

h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #1b576d;
}

p {
    font-size: 16px;
}

