.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: #1b576d;
    color: white;
    font-size: 18px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.username {
    font-weight: bold;
}

.icons {
    display: flex;
    align-items: center;
}

.icon {
    margin-left: 20px;
    cursor: pointer;
    font-size: 24px;
    transition: color 0.3s ease;
}

.icon:hover {
    color: #f4f4f4;
}
