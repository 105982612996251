.button {
    padding: 12px;
    background-color: #1b576d;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
    background-color: #1b576d;
    transform: translateY(-2px);
}

.button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
