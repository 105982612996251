.container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
    margin-bottom: 20px;
    text-align: center;
    color: #1b576d;
}

.error {
    color: red;
    margin-top: 10px;
    font-size: 0.9em;
}

.success {
    color: green;
    margin-top: 10px;
    font-size: 0.9em;
    text-align: center;
}
