.inputContainer {
    margin-bottom: 20px;
    position: relative;
}

label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

input {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #1b576d;
    outline: none;
}

.error {
    color: #ff4d4f;
    font-size: 0.85em;
    margin-top: 5px;
    position: absolute;
    bottom: -20px;
}

.progressBarContainer {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 4px;
    margin-top: 10px;
    height: 10px;
    position: relative;
    overflow: hidden;  /*garante que a barra n ultrapasse os limites do container */
}

.progressBar {
    height: 100%;  /*Garante que a barra toda ocupea altura do container */
    background-color: #4caf50;
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
}

