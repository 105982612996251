.dashboard {
    height: 100vh;  /* Ocupar toda a altura da tela */
    display: flex;
    flex-direction: column;
}

.navbar {
    display: flex;
    justify-content: space-between;
    background-color: #1b576d;
    padding: 15px;
    color: white;
    width: 100%;  /* Ocupar toda a largura da tela */
    box-sizing: border-box;
}

.username {
    font-size: 18px;
}

.balanceSettings {
    display: flex;
    align-items: center;
}

.balance {
    font-size: 16px;
    margin-right: 20px;
}

.settingsIcon {
    font-size: 18px;
    cursor: pointer;
}

.container {
    flex-grow: 1;  /* Ocupar todo o espaço restante */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0;  /* Remover padding */
    margin: 0;   /* Remover margin */
}

h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #1b576d;
}

/***************************** Secao Credito de consulta ************************/

.buyCreditsContainer {
    text-align: center;
    padding: 20px;
}

.title {
    text-align: left;
    font-size: 24px;
    color: #1b576d;
}

.subtitle {
    text-align: left;
    font-size: 16px;
    color: #777;
    margin-bottom: 20px;
}

.packagesContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.packageCard {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.packageCard:hover {
    transform: scale(1.05);
}

.packageCard h3 {
    font-size: 24px;
    color: #1b576d;
}

.packageCard p {
    font-size: 16px;
    margin: 10px 0;
}

.packageDescription {
    color: #555;
    font-size: 14px;
}

.buyButton {
    background-color: #1b576d;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
}

.buyButton:hover {
    background-color: #0056b3;
}


/***************************** Secao upload de arquivo ************************/
.uploadSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Mantém os elementos alinhados à esquerda */
    gap: 5px; /* Reduz o espaço entre o input e o botão */
    margin-top: 15px;
}

.fileInput {
    width: 500px; /* Aumenta o comprimento do componente */
    height: 38px; /* Mesma altura que o botão */
    padding: 5px 10px; /* Ajusta o padding interno */
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.fileInput:hover {
    border-color: #007bff;
}

.uploadContainer {
    display: flex;
    align-items: center; /* Alinha o input e o botão verticalmente */
    gap: 10px; /* Espaço entre o input e o botão */
    margin-top: 15px; /* Espaço entre a lista de instruções e o input */
}

.uploadButton {
    background-color: #1b576d;
    color: white;
    height: 38px; /* Mesma altura que o input */
    padding: 0 15px; /* Tamanho adequado para o botão */
    border-radius: 5px;
    font-size: 14px; /* Fonte menor para harmonizar com o input */
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center; /* Garante que o texto dentro do botão fique alinhado verticalmente */
    justify-content: center; /* Centraliza o texto horizontalmente */
}

.uploadButton:hover {
    background-color: #0056b3;
    transform: scale(1.05); /* Efeito de leve aumento ao passar o mouse */
}
.message {
    margin-top: 10px;
    font-size: 14px;
    color: #28a745; /* Cor verde para sucesso */
}

.instructionsList {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.5;
    list-style-type: disc; /* Para mostrar bolinhas */
    padding-left: 20px; /* Indentação para bolinhas */
    margin-bottom: 15px; /* Adiciona margem abaixo do texto de instrução */
}



/* Secao tabela de historico*/
.historySection {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
}

.message {
    margin-top: 20px;
    color: green;
}

/** fade ao trocar de pagina **/

.content-fade {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.content-fade-active {
    opacity: 1;
}

/** conteiner para icone dashboard and credits **/
.breadcrumbContainer {
    display: flex;
    justify-content: space-between; /* Garante que o breadcrumb fique à esquerda e os créditos à direita */
    align-items: center;
    margin-bottom: 5px;
}

.creditsContainer {
    display: flex;
    align-items: center;
    gap: 8px; /* Espaçamento entre o ícone e o texto */
}

.walletIcon {
    color: #f5c94a; /* Cor do ícone */
    font-size: 20px; /* Tamanho do ícone */
    text-shadow:
            -1px -1px 0 #000,  /* Contorno para o topo esquerdo */
            1px -1px 0 #000,   /* Contorno para o topo direito */
            -1px 1px 0 #000,   /* Contorno para o fundo esquerdo */
            1px 1px 0 #000;    /* Contorno para o fundo direito */
}

.creditsText {
    font-size: 16px;
    color: #333; /* Cor do texto */
}
