/* Global Reset and Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f6f8;
    margin: 0;
    padding: 0;
}

a {
    color: #1976d2;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
